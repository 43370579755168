import React, { useCallback, useRef, useState, useEffect } from 'react';

import Accordion from '../components/Accordion/Accordion';
import Button from '../components/Button/Button';
import Layout from '../components/Layout';
import NewsBlock from '../components/NewsBlock/NewsBlock';
import SEO from '../components/SEO';
import '../styles/about.scss';
import { TeamMembers } from '../utils/contentProvider';

function About() {
  // const [partnerSliderIndex, setpartnerSliderIndex] = useState(0);
  const partnerSlider = useRef();

  useEffect(() => {
    import('tiny-slider/src/tiny-slider').then(({ tns }) => {
      const partnerSliderContainer = document.querySelector('.partner-slider');

      if (partnerSliderContainer) {
        tns({
          container: partnerSliderContainer,
          autoplay: false,
          autoplayButtonOutput: false,
          mouseDrag: true,
          loop: false,
          controls: false,
          nav: true,
          navContainer: '#partner-controls',
          gutter: 0,
          navPosition: 'bottom',

          responsive: {
            0: {
              items: 1,
            },

            768: {
              items: 1,
            },

            1200: {
              items: 1,
            },
          },
        });
      }
      const valuesSliderContainer = document.querySelector('.values-slider');

      if (valuesSliderContainer) {
        tns({
          container: valuesSliderContainer,
          autoplay: false,
          autoplayButtonOutput: false,
          mouseDrag: true,
          loop: false,
          nav: false,
          gutter: 24,
          responsive: {
            0: {
              items: 1.2,
              controls: false,
            },

            768: {
              items: 2.2,
            },

            1200: {
              controls: true,
              items: 3,
            },
          },
        });
      }
    });
  }, []);

  // const partnerSliderClick = useCallback(
  //   (value) => () =>
  //     setpartnerSliderIndex((state) => (state === value ? null : value)),

  //   [],
  // );
  const [showMoreContent, setshowMoreContent] = useState(null);

  const showMoreContentClick = useCallback(
    (value) => () =>
      setshowMoreContent((state) => (state === value ? null : value)),

    [],
  );

  // useEffect(() => {
  //   if (partnerSlider.current) {
  //     partnerSlider.current.slickGoTo(partnerSliderIndex);
  //   }
  // }, [partnerSliderIndex]);

  return (
    <Layout lightHeader>
      <SEO
        title="Live Globally. Bank Globally. | About Aeldra"
        description="A group of global citizens building the world's first truly global consumer bank. Through a single App, customers will be able to seamlessly access all the financial products they need – interest bearing insured bank account, debit card, saving account, investment platform, credit card, home loans and other loans, insurance financial advice, and a lot more."
      />
      <section className="about-hero ">
        <div className="container">
          <h1>For global dreams of global citizens</h1>
        </div>

        {/* <div className="about-video-area relative">
          <div className="container">
            <div className="about-video-thumb cover-image">
              <img src="/img/about-video-thumb.jpg" alt="" />
            </div>

            <button type="button" className="play-button">
              <Icon name="play" fill="#000" />
            </button>
          </div>
        </div> */}
      </section>

      <section className="section-pad">
        <div className="small-container">
          <h2 className="heading ">A neo-bank by bankers</h2>
          <p className="subhead ">
            Started by senior executives from McKinsey, Goldman Sachs,
            Accenture, Infosys and Cargill. Given the complexity of banking*
            regulations within the U.S. and in overseas markets, our founders
            bring deep expertise in regulatory and compliance aspects of
            banking*.
          </p>
          <div className="row">
            <div className="col col-6">
              <div className="single-founder">
                <div className="founder-img cover-image">
                  <img src="/img/sukeert-shanker.jpg" alt="" />
                </div>

                <h3 className="body-font">Sukeert Shanker</h3>
                <h4 className="body-font">CEO</h4>
                <p>
                  Sukeert Shanker, has led the launch of two new digital banks
                  in the last 6 years, including Marcus by Goldman Sachs. Prior
                  to setting up Aeldra, he was Chief Operating Officer of the
                  Digital Bank of East West Bancorp, Inc., a $45 Billion (Rs.
                  330,000 Crores) U.S. bank based in Los Angeles and listed on
                  NASDAQ.
                </p>
              </div>
            </div>
            <div className="col col-6">
              <div className="single-founder">
                <div className="founder-img cover-image">
                  <img src="/img/venkat-gopalakrishnan.jpg" alt="" />
                </div>

                <h3 className="body-font">Venkat Gopalakrishnan</h3>
                <h4 className="body-font">COO</h4>
                <p>
                  Venkat, as Country Head, Nigeria, for Olam, led a $60 million
                  agri commodities business, increasing scale, growing margins
                  and strengthening execution. In his two decades of experience
                  with $1B+ organisations in the Americas, Africa and Asia,
                  where he has successfully built and scaled businesses,
                  evaluated M&amp;A transactions and supported execution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-lightgrey section-pad">
        <div className="container">
          <h2 className="heading heading-mb-50  text-center-mob">The Team</h2>

          <div className="team-block">
            <div className="row">
              {TeamMembers.map(({ name, designation, img, id }) => (
                <div key={id} className="col col-3">
                  <div className="single-team-member">
                    <div className="team-img cover-image">
                      <img src={img} alt="" />
                    </div>
                    <h3 className="body-font">{name}</h3>
                    <p>{designation}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="our-mission">
            <h2 className="body-font">
              Our mission is to provide an easy access and integration into the
              global financial system. A welcoming exclusivity.
            </h2>

            <div className="row">
              <div className="col col-9">
                <p>
                  Aeldra Financial, Inc. ("Aeldra" or "the Company") is a
                  Silicon Valley-based corporation providing access to banking*
                  and investment services in partnership with regulated U.S.
                  financial institutions. The Company was incorporated in 2019
                  and has thousands of customers from the U.S. and overseas,
                  primarily India.
                </p>

                <p style={{ fontStyle: 'italic' }}>
                  “Banking* and finance has always had the insidious feeling of
                  the commercial, the transactional. It needs to be like gourmet
                  food - an essential need but one that elevates your life.” -
                  Sukeert Shanker, CEO Aeldra
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-pad black-bg">
        <div className="container">
          <h2 className="heading text-center heading-mb-50">Our Partners</h2>

          <div
            className="partner-buttons flex justify-center"
            id="partner-controls">
            <button type="button" className="button-unstyled">
              <img src="/img/blue-ridge-bank.png" alt="" />
            </button>
            <button type="button" className="button-unstyled">
              <img src="/img/master-card.svg" alt="" />
            </button>
          </div>

          <div ref={partnerSlider} className="partner-slider">
            <div className="item">
              <div className="partner-single text-center">
                <div>
                  <svg width="20" height="15" fill="none" viewBox="0 0 20 15">
                    <g fill="#fff" opacity=".4">
                      <path d="M6.321 5.998 8.909.694C9.057.38 8.825 0 8.49 0H6.72a.45.45 0 0 0-.379.19L.786 7.68a4.532 4.532 0 0 0-.757 3.073c.231 2.23 2.104 3.998 4.356 4.103a4.61 4.61 0 0 0 4.84-4.608 4.65 4.65 0 0 0-2.904-4.251ZM17.094 5.998 19.683.694C19.83.38 19.599 0 19.262 0h-1.768a.45.45 0 0 0-.378.19L11.56 7.68a4.531 4.531 0 0 0-.758 3.073c.232 2.23 2.105 3.998 4.357 4.103a4.61 4.61 0 0 0 4.84-4.608 4.65 4.65 0 0 0-2.905-4.251Z" />
                    </g>
                  </svg>
                </div>
                <p>
                  Blue Ridge Bank, N.A. (“Blue Ridge”) is Aeldra&apos;s
                  banking** partner. Blue Ridge is a U.S. bank headquartered in
                  Virginia and founded in 1893. The Blue Ridge - Aeldra
                  partnership was established in 2020 with a common mission to
                  provide a wide range of financial services with an emphasis on
                  quality, ethics, and superb customer relations. Blue Ridge is
                  a National Association Bank regulated by the Office of the
                  Comptroller of the Currency (OCC), an independent bureau
                  within the United States Department of the Treasury. Blue
                  Ridge Bankshares Inc., the holding company of Blue Ridge Bank,
                  N.A., is listed on the New York Stock Exchange (NYSE)
                  American. Blue Ridge Website
                </p>

                <p className={showMoreContent === 1 ? '' : 'hidden'}>
                  Blue Ridge disclosed the partnership with Aeldra in their
                  annual 10-K Securities and Securities and Exchange Commission
                  (SEC) filing for FY 2020: "The Company&apos;s efforts to
                  partner with fintech providers started gaining critical mass
                  in 2020. The fintech business ended the year with four active
                  partnerships, including Upgrade, Meritize, Flexible Finance,
                  and Kashable, and six emerging partnerships for 2021 including
                  Jaris, BNK.DEV/Ratchet, Aeldra, Grow Credit, MentorWorks, and
                  Unit. Fintech relationships have resulted in approximately
                  $47.0 million in deposits on the Company&apos;s balance sheet
                  on December 31, 2020." Blue Ridge 10-K Filing 2020
                </p>
                <p>
                  {' '}
                  <button
                    type="button"
                    onClick={showMoreContentClick(1)}
                    className="button-unstyled">
                    Read&nbsp;
                    {showMoreContent === 1 ? 'less...' : 'more...'}
                  </button>
                </p>

                <div>
                  <svg width="21" height="17" fill="none" viewBox="0 0 21 17">
                    <g fill="#fff" opacity=".4">
                      <path d="m14.421 10.089-2.693 5.52c-.154.328.087.722.438.722h1.84a.468.468 0 0 0 .393-.197l5.783-7.797c.613-.898.92-2.015.788-3.198-.241-2.321-2.19-4.161-4.534-4.27A4.798 4.798 0 0 0 11.4 5.664a4.84 4.84 0 0 0 3.023 4.424ZM3.207 10.089l-2.694 5.52c-.154.328.087.722.438.722h1.84a.468.468 0 0 0 .394-.197l5.782-7.797c.613-.898.92-2.015.788-3.198C9.515 2.818 7.565.978 5.221.87A4.798 4.798 0 0 0 .184 5.664a4.84 4.84 0 0 0 3.023 4.424Z" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="partner-single text-center">
                <div>
                  <svg width="20" height="15" fill="none" viewBox="0 0 20 15">
                    <g fill="#fff" opacity=".4">
                      <path d="M6.321 5.998 8.909.694C9.057.38 8.825 0 8.49 0H6.72a.45.45 0 0 0-.379.19L.786 7.68a4.532 4.532 0 0 0-.757 3.073c.231 2.23 2.104 3.998 4.356 4.103a4.61 4.61 0 0 0 4.84-4.608 4.65 4.65 0 0 0-2.904-4.251ZM17.094 5.998 19.683.694C19.83.38 19.599 0 19.262 0h-1.768a.45.45 0 0 0-.378.19L11.56 7.68a4.531 4.531 0 0 0-.758 3.073c.232 2.23 2.105 3.998 4.357 4.103a4.61 4.61 0 0 0 4.84-4.608 4.65 4.65 0 0 0-2.905-4.251Z" />
                    </g>
                  </svg>
                </div>
                <p>
                  Aeldra has a partnership with Mastercard^ to provide a Global
                  Debit Card^ linked to the account* with special benefits
                  relevant for overseas citizens and international travellers.
                </p>
                <p>
                  “Building on our fintech momentum, we have secured additional
                  wins around the globe with new partners like Payoneer and
                  Aeldra in the U.S., HYPE and Flow in Italy, Treezor in France
                  and Prex in Peru.
                  <span className={showMoreContent === 0 ? '' : 'hidden'}>
                    Our fintech customers appreciate our tailored approach,
                    addressing their very specific needs, leveraging our
                    expertise, our tech and of course, our global network.”{' '}
                    <a
                      href="https://www.fool.com/earnings/call-transcripts/2021/01/29/mastercard-ma-q4-2020-earnings-call-transcript/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="gold">
                      Read More
                    </a>
                  </span>
                </p>
                <p className={showMoreContent === 0 ? '' : 'hidden'}>
                  MICHAEL MIEBACH, CEO, MASTERCARD^
                  <br />
                  Aeldra serves thousands of customers in the U.S. and India
                  with a U.S. Bank Account* and a Mastercard^ Debit Card^.{' '}
                </p>

                <p>
                  {' '}
                  <button
                    type="button"
                    onClick={showMoreContentClick(0)}
                    className="button-unstyled">
                    Read&nbsp;
                    {showMoreContent === 0 ? 'less...' : 'more...'}
                  </button>
                </p>

                <div>
                  <svg width="21" height="17" fill="none" viewBox="0 0 21 17">
                    <g fill="#fff" opacity=".4">
                      <path d="m14.421 10.089-2.693 5.52c-.154.328.087.722.438.722h1.84a.468.468 0 0 0 .393-.197l5.783-7.797c.613-.898.92-2.015.788-3.198-.241-2.321-2.19-4.161-4.534-4.27A4.798 4.798 0 0 0 11.4 5.664a4.84 4.84 0 0 0 3.023 4.424ZM3.207 10.089l-2.694 5.52c-.154.328.087.722.438.722h1.84a.468.468 0 0 0 .394-.197l5.782-7.797c.613-.898.92-2.015.788-3.198C9.515 2.818 7.565.978 5.221.87A4.798 4.798 0 0 0 .184 5.664a4.84 4.84 0 0 0 3.023 4.424Z" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <NewsBlock />

      <section className="section-gap">
        <div className="container">
          <div className="writing-about-aeldra bg-lightgrey ">
            <div className=" row align-center">
              <div className="col col-8">
                <h2 className="body-font">
                  Writing about Aeldra? Download logos and product shots
                </h2>
              </div>
              <div className="col col-4">
                <Button
                  href="/zip/aeldra-brand-kit.zip"
                  iconName="download"
                  lessRound>
                  Download Complete Kit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="black-bg section-pad" style={{ overflowX: 'hidden' }}>
        <div className="container">
          <h2 className="heading text-center">Our Values</h2>
          <p className="subhead text-center">
            We are solving for banking* equitability.
          </p>
          <div className="slider-overflow-visible">
            <div className="values-slider equal-height ">
              <div className="item">
                <div className="icon-text-block">
                  <div className="icon-wrapper">
                    <svg width="22" height="24" fill="none" viewBox="0 0 22 24">
                      <path
                        fill="#fff"
                        d="M17.233 10.5c.023.289-.078.589-.3.811L10.244 18c-1.477 1.478-2.966 1.478-4.455 0l-4.456-4.456c-.755-.766-1.133-1.533-1.11-2.3H.3l16.8-.733.133-.011Z"
                      />
                      <path
                        fill="#fff"
                        d="M16.934 9.822 8.767 1.656 7.8.689a.838.838 0 0 0-1.177 0 .838.838 0 0 0 0 1.178l.966.966L1.334 9.09C.623 9.8.244 10.522.223 11.244H.3l16.8-.733.134-.01a1.055 1.055 0 0 0-.3-.679Z"
                        opacity=".4"
                      />
                      <path
                        fill="#fff"
                        d="M15.778 23.277H1.333a.84.84 0 0 1-.833-.833.84.84 0 0 1 .833-.833h14.445a.84.84 0 0 1 .833.833.84.84 0 0 1-.833.834ZM19.5 14.422a.778.778 0 0 0-1.111 0c-.345.378-2.056 2.311-2.056 3.767a2.608 2.608 0 0 0 2.611 2.611 2.608 2.608 0 0 0 2.611-2.611c0-1.456-1.71-3.389-2.055-3.767Z"
                      />
                    </svg>
                  </div>

                  <h3 className="body-font"> Design first</h3>
                  <p>
                    The Aeldra mobile App has been inspired by the Apple mantra
                    “Simplicity is the ultimate sophistication”. The user
                    experience and money movement functionality are designed to
                    be extremely intuitive and easy to use even by customers not
                    familiar with the U.S. banking* system. The Aeldra
                    App&apos;s minimalist design hides the immense complexity in
                    enabling cross-border banking**, with numerous integrations,
                    latest technologies and proprietary machine learning
                    algorithms under the cover.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="icon-text-block">
                  <div className="icon-wrapper">
                    <svg width="23" height="23" fill="none" viewBox="0 0 23 23">
                      <path
                        fill="#fff"
                        d="M5.688 8.278v-1.3c0-3.256.923-5.422 5.423-5.422s5.422 2.166 5.422 5.422v1.3c.566.01 1.078.033 1.555.1v-1.4c0-3-.722-6.978-6.977-6.978-6.256 0-6.978 3.978-6.978 6.978v1.389c.466-.056.989-.09 1.555-.09Z"
                        opacity=".4"
                      />
                      <path
                        fill="#fff"
                        d="M16.278 8.267H5.944C1.39 8.267 0 9.655 0 14.21v2.067c0 4.555 1.389 5.944 5.944 5.944h10.334c4.555 0 5.944-1.389 5.944-5.944V14.21c0-4.556-1.389-5.944-5.944-5.944ZM11.11 18.6a3.36 3.36 0 0 1-3.355-3.356 3.36 3.36 0 0 1 3.355-3.355 3.36 3.36 0 0 1 3.356 3.355A3.36 3.36 0 0 1 11.11 18.6Z"
                      />
                      <path
                        fill="#fff"
                        d="M11.11 17.056a1.811 1.811 0 1 0 0-3.622 1.811 1.811 0 0 0 0 3.622Z"
                        opacity=".4"
                      />
                    </svg>
                  </div>

                  <h3 className="body-font">Security first</h3>
                  <p>
                    Aeldra believes in Privacy and Info Security above all else,
                    given the importance of cross-border banking* in
                    people&apos;s lives. Aeldra uses the latest technology and
                    documented processes &amp; procedures to ensure that
                    customers&apos; data is safe at all times. A guiding
                    principle for the company is that the customer&apos;s data
                    belongs to the customer. All passport scans, personal
                    information, and transaction information is completely
                    secure and confidential. Aeldra does not provide or sell
                    customers&apos; data to any third party, and never will.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="icon-text-block">
                  <div className="icon-wrapper">
                    <svg width="23" height="23" fill="none" viewBox="0 0 23 23">
                      <path
                        fill="#fff"
                        d="M5.688 8.278v-1.3c0-3.256.923-5.422 5.423-5.422s5.422 2.166 5.422 5.422v1.3c.566.01 1.078.033 1.555.1v-1.4c0-3-.722-6.978-6.977-6.978-6.256 0-6.978 3.978-6.978 6.978v1.389c.466-.056.989-.09 1.555-.09Z"
                        opacity=".4"
                      />
                      <path
                        fill="#fff"
                        d="M16.278 8.267H5.944C1.39 8.267 0 9.655 0 14.21v2.067c0 4.555 1.389 5.944 5.944 5.944h10.334c4.555 0 5.944-1.389 5.944-5.944V14.21c0-4.556-1.389-5.944-5.944-5.944ZM11.11 18.6a3.36 3.36 0 0 1-3.355-3.356 3.36 3.36 0 0 1 3.355-3.355 3.36 3.36 0 0 1 3.356 3.355A3.36 3.36 0 0 1 11.11 18.6Z"
                      />
                      <path
                        fill="#fff"
                        d="M11.11 17.056a1.811 1.811 0 1 0 0-3.622 1.811 1.811 0 0 0 0 3.622Z"
                        opacity=".4"
                      />
                    </svg>
                  </div>

                  <h3 className="body-font">Cocreation first</h3>
                  <p>
                    Another unique element of Aeldra&apos;s business model is
                    the relationship with customers - Aeldra believes in
                    co-creating the cross-border banking* experience with
                    customers to ensure that their needs are met. The Aeldra
                    leadership team is in constant touch with customers to learn
                    what functionality can be added or improved, and incorporate
                    customer suggestions with great urgency. The Company
                    believes that this unique culture and Agile approach will be
                    critical to the success of the company in fulfilling its
                    vision to be the first truly global consumer bank serving
                    citizens everywhere who need cross-border banking*.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="icon-text-block">
                  <div className="icon-wrapper">
                    <svg width="23" height="23" fill="none" viewBox="0 0 23 23">
                      <path
                        fill="#fff"
                        d="m11.779 4.422.655 1.312a.805.805 0 0 0 .534.388l1.189.2c.755.123.933.678.389 1.223l-.923.922a.787.787 0 0 0-.188.678l.266 1.144c.211.9-.267 1.256-1.067.778l-1.11-.656a.779.779 0 0 0-.734 0l-1.111.656c-.8.467-1.278.122-1.067-.778l.267-1.144c.044-.211-.033-.523-.189-.678l-.922-.922C7.223 7 7.4 6.455 8.157 6.322l1.189-.2a.81.81 0 0 0 .522-.388l.655-1.312c.323-.71.9-.71 1.256 0ZM21.412 18.3l-1.834.433a1.09 1.09 0 0 0-.822.823l-.389 1.633c-.21.889-1.344 1.156-1.933.456l-5.3-6.09-5.3 6.1c-.589.7-1.722.434-1.933-.455l-.39-1.633a1.107 1.107 0 0 0-.821-.822L.856 18.31c-.844-.2-1.144-1.255-.533-1.866l4.333-4.334a7.762 7.762 0 0 0 5.311 3.356c.378.067.767.1 1.167.1s.789-.033 1.167-.1a7.762 7.762 0 0 0 5.31-3.356l4.334 4.334c.611.6.311 1.655-.533 1.855Z"
                      />
                      <path
                        fill="#fff"
                        d="M18.912 7.778a7.742 7.742 0 0 1-1.3 4.322 7.762 7.762 0 0 1-5.31 3.356c-.378.066-.767.1-1.167.1s-.79-.034-1.167-.1A7.762 7.762 0 0 1 4.657 12.1a7.742 7.742 0 0 1-1.3-4.322C3.357 3.478 6.835 0 11.135 0c4.3 0 7.777 3.478 7.777 7.778Z"
                        opacity=".4"
                      />
                    </svg>
                  </div>

                  <h3 className="body-font">Nobility first</h3>
                  <p>
                    “Aeldra” (pronounced “Eldra”) means Noble in old English. It
                    was chosen to reflect the values of integrity, trust and
                    customer advocacy that the company adheres to.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-pad">
        <div className="container">
          <div className="row">
            <div className="col-md col-8 offset-3">
              <h2 className="heading text-center-mob">We're all ears.</h2>
              <p className="subhead text-center-mob">
                There&apos;s no such thing as a stupid question.
              </p>

              <div className="home-faq">
                <Accordion />
              </div>
              <div style={{ marginTop: 40 }}>
                <Button href="#!">View all FAQs</Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default About;
